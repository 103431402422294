import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout";
import {P, Input} from "@dnb/eufemia";
import { CustomButton } from '../components/styledComponents';
import { navigate } from "gatsby";

const SecondPage = () => (
  <Layout>
    <h1>We now need some very personal information from you</h1>
    <P>This enables us to sell said information if you snitch on us</P>
    <P bottom="2rem">We will not delete your information if you ask us to, we will keep it forever</P>

    <div><Input bottom="1rem" label="Insert credit card information below"></Input> </div>
    <div><Input bottom="1rem" label="Pin code for debit card or credit card, depending on which one's more loaded"></Input> </div>
    <div><Input bottom="1rem" label="Full name"></Input> </div>
    <div><Input bottom="1rem" label="Address"></Input> </div>
    <div><Input bottom="1rem" label="Password to all social medias"></Input> </div>
    <div><Input bottom="1rem" label="Any family secrets"></Input> </div>
    <CustomButton onClick={() => navigate("/marketplace/")}>Go shopping</CustomButton>

    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default SecondPage
