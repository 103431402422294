import styled from "@emotion/styled"
import { Button } from "@dnb/eufemia";

export const CustomButton = styled(Button)`
  &.dnb-button {
    background: #3c3c3d;
    margin-top: 1.5rem;
  }
`;

